import React from "react"
import BackgroundImage from "gatsby-background-image"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import classes from "./index.module.css"

const TourGuideItem = ({ index, tour }) => {
  return (
    <Row
      noGutters
      style={{
        flexDirection: index % 2 === 0 ? "row-reverse" : "row",
        alignItems: "center",
      }}
    >
      <Col sm={6} xs={12}>
        <BackgroundImage
          Tag={`div`}
          className={classes.TourImage}
          fluid={tour.cover.localFile.childImageSharp.fluid}
        />
      </Col>
      <Col sm={6} xs={12}>
        <div className={classes.TourInfo}>
          <h4 className={classes.TourInfo__title}>{tour.title}</h4>
          <p className={classes.TourInfo_desc}>{tour.description}</p>
        </div>
      </Col>
    </Row>
  )
}

export default TourGuideItem
