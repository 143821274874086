import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Container from "react-bootstrap/Container";
import StyledHero from "../components/StyledHero/StyledHero";
import TourGuideItem from "../components/TourGuideItem";

const TourGuide = ({ data }) => {
  const { heroImage, tourGuide } = data
  const tourGuideStruct = []

  Array.from({ length: 9 }).forEach((_, index) => {
    tourGuideStruct.push({
      title: tourGuide.acf[`title_${index + 1}`],
      description: tourGuide.acf[`description_${index + 1}`],
      cover: tourGuide.acf[`cover_${index + 1}`],
    });
  });
  return (
    <Layout title="Tour Guide">
      <StyledHero image={heroImage.childImageSharp.fluid}>
        <h1 style={{ textTransform: "uppercase" }}>Tour Guide</h1>
        <h4>Explore Beauty of Jaisalmer</h4>
      </StyledHero>
      <Container fluid className="p-0">
        {
          tourGuideStruct.map((tour,index) => <TourGuideItem index={index+1} tour={tour}/>)
        }
      </Container>
    </Layout>
  )
};

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "Heros/contact-page.jpg" }) {
      id
      childImageSharp {
        fluid(maxWidth: 1920, maxHeight: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    tourGuide: wordpressPage(slug: { eq: "tour-guide" }) {
      id
      slug
      acf {
        title_1
        description_1
        cover_1 {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, maxHeight: 1080) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        title_2
        description_2
        cover_2 {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, maxHeight: 1080) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        title_3
        description_3
        cover_3 {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, maxHeight: 1080) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        title_4
        description_4
        cover_4 {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, maxHeight: 1080) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        title_5
        description_5
        cover_5 {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, maxHeight: 1080) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        title_6
        description_6
        cover_6 {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, maxHeight: 1080) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        title_7
        description_7
        cover_7 {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, maxHeight: 1080) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        title_8
        description_8
        cover_8 {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, maxHeight: 1080) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        title_9
        description_9
        cover_9 {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, maxHeight: 1080) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
export default TourGuide;
