import React from 'react'; 
import Img from 'gatsby-image'; 
import styles from './StyledHero.module.css';
const StyledHero = ({image, children}) => {
  
    return (
        <div className={styles.Hero}>
            <Img fluid={image} 
                alt="Hero"
                objectFit="cover"
                objectPosition="50% 50%"/>
            <div className={styles.InfoBox}>
                {children}
            </div>
        </div>
    );
}

export default StyledHero;
